<template>
  <div class="pg-dashboard pg-page">
    <div class="pg-header pt-5 pb-3">
      <img :src="app.koperasi_logo" alt="">
      <!-- <h1>Mobile {{app.koperasi_name}}</h1> -->
      <h6>Transaksi Histori</h6>
      <div class="pg-header-nav-btn">
        <router-link to="/profile">
          <i class="fas fa-cog"></i>
        </router-link>
      </div>
    </div>
    <div class="pg-content pg-content-histori-transaksi">
      <b-row>
        <b-col sm=12>
          <b-form-group>
            <b-input-group>
              <template #prepend>
                <b-input-group-text>
                  <i class="fas fa-credit-card"></i>
                </b-input-group-text>
              </template>
              <b-input placeholder="No Rekening" v-model="user.cif_no" disabled/>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col xs=6>
          <b-form-group>
            <b-form-datepicker placeholder="From" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="id" v-model="transaksi.from" @input="doGet()"/>
          </b-form-group>
        </b-col>
        <b-col xs=6>
          <b-form-group>
            <b-form-datepicker placeholder="To" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" locale="id" v-model="transaksi.to" @input="doGet()"/>
          </b-form-group>
        </b-col>
      </b-row>
      <b-overlay :show="transaksi.loading">
        <div v-if="transaksi.data.length > 0">
          <div class="pg-saldo-pembiayaan mt-3" v-for="(tr,trIndex) in transaksi.data" :key="`saldo-${trIndex}`" :class="(tr.type == 'Masuk') ? 'green' : null">
            <div class="font-12 d-flex justify-content-between align-items-center">
              <label>{{tr.trx_date}}</label>
            </div>
            <h1>Transaksi {{tr.type}}</h1>
            <div class="font-12 d-flex justify-content-between align-items-center">
              <span>{{tr.description}}</span>
            </div>
            <div class="font-12 d-flex justify-content-between align-items-center">
              <label>Nominal</label>
              <span>{{tr.amount}}</span>
            </div>
            <div class="font-12 d-flex justify-content-between align-items-center">
              <label>Saldo Awal</label>
              <span>{{tr.saldo_awal}}</span>
            </div>
            <div class="font-12 d-flex justify-content-between align-items-center">
              <label>Saldo Akhir</label>
              <span>{{tr.saldo_akhir}}</span>
            </div>
          </div>
        </div>
        <div v-else>
          <b-alert variant="warning" :show="!transaksi.loading">Tidak ada transaksi pada rentan waktu ini</b-alert>
        </div>
      </b-overlay>
    </div>
  </div>
</template>
<script>
import {
  mapGetters,
  mapActions
} from "vuex";
import axios from 'axios'
import {baseUrl,settings} from '../config'
export default {
  data(){
    return {
      app :settings,
      transaksi : {
        data : [],
        loading : false,
        from : new Date('01/01/2021'),
        to : new Date('12/31/2021'),
      },
      hitlog : {
        cif_no: null,
        timestamp : null,
        menu_akses : null
      }
    }
  },
  computed: {
    ...mapGetters(["user"])
  },
  methods : {
    doGet(){
      this.transaksi.loading = true
      let url = `${baseUrl}/m_statement_wallet`
      let payloadData = {
        cif_no : this.user.cif_no,
        token : this.user.token,
        from : this.formatDate(this.transaksi.from),
        thru : this.formatDate(this.transaksi.to),
      }
      let payload = new FormData()
      for(let key in payloadData){
        payload.append(key,payloadData[key])
      }
      axios
      .post(url,payload)
      .then((res)=>{
        this.transaksi.loading = false
        this.transaksi.data = []
        if(res.data[0].amount != 'TIDAK ADA DATA') {
          res.data.map((x) => {
            if(x.flag_dc == 'D')
              x.type = 'Keluar'
            else
              x.type = 'Masuk'
            this.transaksi.data.push(x)
          })
        }
      })
      .catch((res)=>{
        this.transaksi.loading = false
        this.notif('Error',res.message,'danger')
      })
    },
    getHitlog(){
      this.hitlog.loading = true
      let url = `${baseUrl}/histori_login`
      let payloadData = {
        cif_no : this.user.cif_no,
        timestamp : this.user.timestamp,
        menu_akses : 'TransaksiHostori'
      }

      let payload = new FormData()
      for(let key in payloadData){
        payload.append(key,payloadData[key])
      }
      axios
      .post(url,payload)
      .then((res)=>{
        this.hitlog.loading = false
        this.hitlog = res.data
      })
      .catch((res)=>{
        this.hitlog.loading = false
        this.notif('Error',res.message,'danger')
      })
    },
    formatDate(date){
      var dateObj = new Date(date);
      var month = dateObj.getUTCMonth() + 1; //months from 1-12
      var day = dateObj.getUTCDate();
      var year = dateObj.getUTCFullYear();

      return day + "/" + month + "/" + year;
    }
  },
  mounted(){
    this.doGet()
    this.getHitlog()
  }
}
</script>